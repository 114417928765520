import * as React from 'react';
import { Flex } from 'rebass/styled-components';
import Layout from '../components/Layout';
import MainHeader from '../components/MainHeader';
import HomepageETrainings from '../components/HomepageETrainings';
import NewsRow from '../components/NewsRow';
import Logos from '../components/Logos';

const IndexPage = () => {
  return (
    <Layout>
      <NewsRow />
      <MainHeader />
      <Flex width={1} justifyContent={'center'}>
        <HomepageETrainings />
      </Flex>
      <Flex variant={'wrapper'}>
        <Logos />
      </Flex>
    </Layout>
  );
};

export default IndexPage;
