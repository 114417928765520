import { Box, Image } from 'rebass/styled-components';
import React from 'react';
import logo from '../images/el.png';

export default function FounderImage() {
  return (
    <Box
      marginLeft={[0, 0, '-70px']}
      alignSelf={'center'}
      width={[0, 0, '100%']}
    >
      <Image src={logo} />
    </Box>
  );
}
