import { Box, Flex, Image } from 'rebass/styled-components';
import * as React from 'react';
import ecrklex from '../images/ecrklex.png';
import edulex from '../images/edulex.png';
import bhp from '../images/bhp.png';
import prymat from '../images/prymat.png';
import sygnet from '../images/sygnet.png';

const imageStyle = {
  maxWidth: 200,
  marginTop: [50, null],
  marginX: 50,
};
export default function Logos() {
  return (
    <Flex
      marginY={80}
      alignItems={'center'}
      flexWrap={'wrap'}
      flexDirection={'row'}
      width={'100%'}
      justifyContent={'space-around'}
    >
      <Box sx={imageStyle}>
        <a className="logo-link" href="https://ecrklex.pl" target="blank">
          <Image src={ecrklex} />
        </a>
      </Box>
      <Box sx={imageStyle}>
        <a className="logo-link" href="https://edulex.com.pl/" target="blank">
          <Image src={edulex} />
        </a>
      </Box>
      <Box sx={imageStyle}>
        <a className="logo-link" href="https://hsp.waw.pl/" target="blank">
          <Image src={bhp} />
        </a>
      </Box>
      <Box sx={imageStyle}>
        <a
          className="logo-link"
          href="http://prymat.biasoft.net/"
          target="blank"
        >
          <Image src={prymat} />
        </a>
      </Box>
      <Box sx={imageStyle}>
        <a
          className="logo-link"
          href="https://www.pwsz.suwalki.pl/"
          target="blank"
        >
          <Image src={sygnet} />
        </a>
      </Box>
    </Flex>
  );
}
