import { Box, Flex, Image, Text } from 'rebass/styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

export default function NewsList({ news }) {
  return news.map((n) => (
    <Flex
      mb={'15px'}
      maxHeight={'30%'}
      key={n._id}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Box marginRight={15} flex={1}>
        <Link to={`/aktualnosci/${n.slug}`}>
          <Image src={n.imageSrc} />
        </Link>
      </Box>
      <Box flex={3}>
        <Link to={`/aktualnosci/${n.slug}`}>
          <Text
            fontFamily={'Open Sans'}
            fontSize={'20px'}
            color="text"
            className={'news-link'}
          >
            {n.title}
          </Text>
        </Link>
      </Box>
    </Flex>
  ));
}
NewsList.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      imageSrc: PropTypes.string.isRequired,
    }),
  ),
};
