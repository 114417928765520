import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import { useQuery } from '@apollo/client';
import Interweave from 'interweave';
import getRecommendationsQuery from '../graphql/queries/getRecommendations.graphql';
import getDateInFormat from '../utils/getDateInFormat';
import { maxOnlineTrainingsImageWidth } from './MainHeader';

function ReviewsSlider() {
  const { data, loading } = useQuery(getRecommendationsQuery, {
    variables: {
      page: 1,
    },
    fetchPolicy: 'cache-first',
  });
  const [index, setIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((currentValue) => {
        return currentValue + 1;
      });
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  if (
    (loading && !data) ||
    data.getRecommendationsForClient.list.length === 0
  ) {
    return null;
  }
  const opinions = data.getRecommendationsForClient.list;
  const currentIndex =
    (hoveredIndex !== null ? hoveredIndex : index) % opinions.length;
  const currentOpinion = opinions[currentIndex];
  return (
    <Flex
      sx={{
        zIndex: 2,
        backgroundColor: 'background',
        marginLeft: 3,
      }}
      height={260}
      flexDirection={'column'}
      width={[1, 3 / 4, null]}
    >
      <Flex
        marginTop={50}
        flexDirection={'column'}
        marginX={[null, 60]}
        onMouseEnter={() => {
          setHoveredIndex(index);
        }}
        onMouseLeave={() => {
          setHoveredIndex(null);
        }}
      >
        <Heading
          fontFamily={'Oswald'}
          marginBottom={'10px'}
          fontSize={20}
          color="primary"
          fontWeight={'lighter'}
        >
          OPINIE KLIENTÓW
        </Heading>
        <Text
          fontFamily={'Open Sans'}
          fontSize={16}
          fontWeight={'lighter'}
          color={'silent'}
        >{`${getDateInFormat(currentOpinion.date)}`}</Text>
        <div className={'no-overflow-multi-line-text'}>
          <Interweave content={currentOpinion.text} />
        </div>
        <Text
          fontFamily={'Open Sans'}
          fontSize={20}
          fontWeight={'600'}
          marginTop={20}
        >{`${currentOpinion.person}`}</Text>
      </Flex>
    </Flex>
  );
}

export default ReviewsSlider;
