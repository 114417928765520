import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Button, Flex, Heading } from 'rebass/styled-components';
import Link from 'gatsby-link';
import getLatestProducts from '../graphql/queries/getLatestProducts.graphql';
import TrainingThumbnail from './TrainingThumbnail';
import Loader from './Loader';

function HomepageETrainings() {
  const { data, loading } = useQuery(getLatestProducts);
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  useEffect(() => {
    if (!width) {
      setWidth(elementRef.current.offsetWidth);
    }
    const handleResize = () => {
      setWidth(elementRef.current.offsetWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [elementRef]);
  const elementsToDisplay = width < 700 ? 1 : 4;
  return (
    <Flex
      variant={'wrapper'}
      flexDirection={'column'}
      mt={[60, 100]}
      width={'100%'}
      ref={elementRef}
    >
      <Heading
        color="text"
        fontSize={[40, 50]}
        fontWeight={'bold'}
        fontFamily={'Oswald'}
        marginY={30}
        alignSelf={'flex-start'}
      >
        E-szkolenia
      </Heading>
      <Flex flexWrap={'wrap'} justifyContent={['flex-start', 'space-around']}>
        <>
          {!loading && data && data.getLatestProducts ? (
            data.getLatestProducts
              .slice(0, elementsToDisplay)
              .map((thumbnail) => (
                <TrainingThumbnail
                  data={thumbnail}
                  key={thumbnail._id}
                  type={'e-szkolenia'}
                  showShort={false}
                />
              ))
          ) : (
            <Loader />
          )}
          <Flex
            width={[300, 360]}
            minWidth={300}
            marginRight={40}
            my={10}
            justifyContent="center"
            alignItems={'center'}
          >
            <Link to="/e-szkolenia">
              <Button marginTop={30} backgroundColor={'primary'} py={10}>
                Zobacz wszystkie
              </Button>
            </Link>
          </Flex>
        </>
      </Flex>
    </Flex>
  );
}

export default HomepageETrainings;
