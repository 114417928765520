import React from 'react';
import { Button, Flex, Image, Text } from 'rebass/styled-components';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';

const TrainingThumbnail = ({
  data: { title, imageSrc, short, slug },
  type,
  showShort,
  isRedirectingExt,
}) => {
  const link = `/${type}/${slug}`;
  const ecrkDocLink = `https://ecrklex.pl/produkty/${slug}`;
  const buttonLink = isRedirectingExt ? ecrkDocLink : link;
  return (
    <Flex
      width={[300, 360]}
      minWidth={300}
      marginRight={40}
      my={10}
      alignItems="center"
      fontFamily={'Oswald'}
      justifyContent="center"
      alignContent="space-between"
      textAlign={'center'}
      flexWrap={'wrap'}
      flexDirection={'row'}
      variant={'borderBox'}
    >
      <Image src={imageSrc} width={1} />
      <Flex mx={3} minHeight={'320px'} flexDirection="column">
        <Text
          color="primary"
          fontSize={[19, 20]}
          fontWeight={'500'}
          display="block"
          marginTop={40}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            flex: '1',
          }}
        >
          {title}
        </Text>
        {showShort && short && (
          <Text
            marginTop={20}
            color="text"
            fontSize={18}
            textAlign="center"
            fontWeight={'300'}
            fontFamily={'Open Sans'}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {short}
          </Text>
        )}
        <Link to={buttonLink}>
          <Button fontSize={18} marginY={40}>
            Zobacz
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};

TrainingThumbnail.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    short: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
  showShort: PropTypes.bool,
  isRedirectingExt: PropTypes.bool,
};

TrainingThumbnail.defaultProps = {
  type: 'szkolenia-online',
  short: null,
  showShort: true,
  isRedirectingExt: false,
};

export default TrainingThumbnail;
