import { Box, Button, Flex, Text } from 'rebass/styled-components';
import Link from 'gatsby-link';
import React from 'react';

export default function OnlineTrainingsOffer() {
  return (
    <Flex
      flex={1}
      marginRight={[null, null, 100]}
      justifyContent={['flex-start', 'center', null]}
      flexWrap={'wrap'}
      marginLeft={20}
    >
      <Box maxWidth={800}>
        <Text
          color="text"
          fontSize={[40, 50]}
          fontWeight={'bold'}
          fontFamily={'Oswald'}
        >
          Szkolenia Online
        </Text>
        <Text
          fontFamily={'Open Sans'}
          color="text"
          textAlign={'left'}
          marginY={40}
          fontSize={20}
        >
          Nasza oferta szkoleń online jest skierowana przede wszystkim do Rad
          Pedagogicznych, ale także do Dyrektorów szkół i pracowników
          administracji
        </Text>
        <Link to="/szkolenia-online">
          <Button marginTop={[20, 60]} backgroundColor={'primary'}>
            Zobacz ofertę
          </Button>
        </Link>
      </Box>
    </Flex>
  );
}
