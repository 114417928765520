import { Flex, Heading, Text } from 'rebass/styled-components';
import React from 'react';

export default function CompanyDescription() {
  return (
    <Flex
      width={[3 / 4, 1, null]}
      flexDirection="column"
      justifyContent={'space-between'}
      marginY={40}
      marginX={[null, 10, 10]}
    >
      <Heading
        fontFamily={'Oswald'}
        lineHeight={'heading'}
        fontSize={['28px', '28px', '35px']}
        color="text"
        fontWeight={'bold'}
      >
        Najwyższa jakość szkoleń poparta wieloletnim doświadczeniem.
      </Heading>
      <Text
        fontFamily={'Open Sans'}
        fontSize={['20px', '20px', '20px']}
        color="text"
        marginY={'10px'}
      >
        ODN „PROLEGIS” jest ogólnopolską placówką szkoleniową. Oferta Ośrodka
        jest kierowana do dyrektorów szkół, rad pedagogicznych oraz pracowników
        szkolnej administracji.
      </Text>
      <Text
        fontFamily={'Open Sans'}
        fontSize={['20px', '20px', '20px']}
        color="text"
      >
        Organ prowadzący ODN
        <br />
        Elżbieta Linowska
      </Text>
    </Flex>
  );
}
