import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Button, Flex, Text } from 'rebass/styled-components';
import { navigate } from 'gatsby-link';
import getNewsQuery from '../graphql/queries/getNews.graphql';
import NewsList from './NewsList';
import Loader from './Loader';

export default function NewsSection() {
  const { data, loading } = useQuery(getNewsQuery, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Flex
      width={1}
      flexDirection={'column'}
      marginTop={[null, 20, 40]}
      marginBottom={[30, 0, 0]}
    >
      <Box
        marginLeft={[null, 10, 20]}
        marginTop={[null, 30, 30]}
        marginBottom={30}
      >
        <Text color={'primary'} fontFamily={'Oswald'} fontSize={25}>
          Aktualności
        </Text>
      </Box>
      {!loading && data && data.getNews && data.getNews.length > 2 ? (
        <NewsList news={data.getNews.slice(0, 3)} />
      ) : (
        <Box alignSelf={'center'}>
          <Loader />
        </Box>
      )}
      <Button
        alignSelf={'end'}
        marginTop={30}
        onClick={() => {
          navigate(`/aktualnosci/`);
        }}
      >
        Czytaj więcej
      </Button>
    </Flex>
  );
}
