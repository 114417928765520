import React from 'react';
import { Flex } from 'rebass/styled-components';
import NewsSection from './NewsSection';
import CompanyDescription from './CompanyDescription';
import FounderImage from './FounderImage';

export default function NewsRow() {
  return (
    <Flex variant={'wrapper'} flexDirection={['column', 'row', 'row']}>
      <FounderImage />
      <CompanyDescription />
      <NewsSection />
    </Flex>
  );
}
