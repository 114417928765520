import React from 'react';
import { Box, Flex, Image } from 'rebass/styled-components';
import mainHeaderLogo from '../images/mainheader.png';
import ReviewsSlider from './ReviewsSlider';
import OnlineTrainingsOffer from './OnlineTrainingsOffer';

export const maxOnlineTrainingsImageWidth = 1500;
const negativeImageMargin = 10;
const MainHeader = () => {
  return (
    <Box mt={[20, 80]}>
      <Flex flexDirection={'row'}>
        <Flex
          flex={[0, 3]}
          maxWidth={maxOnlineTrainingsImageWidth}
          marginLeft={`-${negativeImageMargin}px`}
        >
          <Image src={mainHeaderLogo} />
        </Flex>
        <OnlineTrainingsOffer />
      </Flex>
      <Box
        sx={{ position: 'relative' }}
        fontSize={[12, 16, 20]}
        marginTop={[0, -250, -250]}
        variant={'wrapper'}
        maxWidth={maxOnlineTrainingsImageWidth - negativeImageMargin}
      >
        <ReviewsSlider />
      </Box>
    </Box>
  );
};

export default MainHeader;
